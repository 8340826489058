import React from 'react'
import { Hero } from '../../components'
import Body from '../../components/Body/Body'

function Home() {
    return (
        <>
            <Hero/>
            <Body/>
        </>
    )
}

export default Home
