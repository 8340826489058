
import React from 'react'
import { Notification, NotificationCanceButton, NotificationContainer, NotificationMessage, NotificationTextContainer, NotificationTitle, ToastElement } from './Toast.elements';
// error="#f04e4e";
// sucess = "#5cb85c";
// info = "#5bc0de";
type ToastTypeName =|'Error'|'Sucess'|'Info' ;
var background:string = "";


type toastProps = {
    description:string;
    type:ToastTypeName;
    closeClick:any;
}

const Toast:React.FC<toastProps>=({description,type,closeClick})=>{
    if(type === 'Error'){
        background  = '#f04e4e';
    }else if(type === 'Sucess'){
        background = '#5cb85c';
    }else{
        background = '#5bc0de';
    }
    return (
        <>
            <NotificationContainer>
                    <Notification background={background}>
                        {/* <ToastElement>
                            <NotificationCanceButton>
                                X
                            </NotificationCanceButton>

                            <div>
                                <NotificationTitle>
                                    {type}
                                </NotificationTitle>
                                <NotificationMessage>
                                    {description}
                                </NotificationMessage>
                            </div>

                        </ToastElement> */}
                        <ToastElement>
                            <NotificationTextContainer>
                                <NotificationTitle>
                                    {type}
                                </NotificationTitle>
                                <NotificationMessage>
                                    {description}
                                </NotificationMessage>
                            </NotificationTextContainer>
                            <NotificationCanceButton onClick={()=>{closeClick()}}>
                                X
                            </NotificationCanceButton>
                        </ToastElement>
                    </Notification>
            </NotificationContainer>
        </>
    )
}

export default Toast
