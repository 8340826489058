import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Popup from 'reactjs-popup'
import { ProgressChart, Toast } from '../..'
import { ApiClient } from '../../../Data/ApiClient'
import { Category } from '../../../Data/Classes/Category'
import { Photo } from '../../../Data/Classes/Photo'
import { ServiceProvider } from '../../../Data/Classes/Service_provider'
import { User } from '../../../Data/Classes/User'
import { AppFormInput, AppGlobalDeleteButton, AppSuccessButton, FormInputSelect } from '../../../pages/UserLogin/Login.elements'
import { NumberFormatter } from '../../Common/Config'
import GlobalProgressLoader from '../../Common/GlobalLoader'
import { EditLoaderOverlay, EditModalContainer, EditTopSection, GuestEditHeader, MiddleSection } from '../../Modal/Modal.elements'
import SpPackage from '../../PackageCard/Package'
import { PackageWrapper } from '../../PackageCard/Style'

import { ChartTitle } from '../../ProgressChart/ProgressChart.elements'


import { HomeSectionHolder, SectionCardHolder,IndividualCard, TopSectionHolder, SectionTitle, IndividualCardTitle, IndividualCardAmount, MoneyBillWave, Cash, TakeMyMoney, ChartSectionHolder, ListingCardContainer, ListItem, ListingContents, SpProfile, SpSectionHolder, SpUserCard, SpNameContainer, SpProfileText, SpDescriptionContainer, SpDescription, SpEditButton, SpPackagesSection, SpCategoryContainer, SpCategory, SpPackageAdd, SpPackageHeaderContainer, ListItemAMount } from './Home.elements'
import { useHistory } from 'react-router-dom'
type homeOptions = {
    eventId:string;
    userDataObj:User|undefined;
}
const MainDashboard:React.FC<homeOptions>=({eventId,userDataObj})=> {
   const history = useHistory()

    const [TotalBudget, setTotalBudget] = useState(0);
    const [TotalCollectedBudget, setTotalCollectedBudget] = useState(0);
    const [TotalPledge, setTotalPledge] = useState(0);
    const [TotalFund, setTotalFund] = useState(0);
    const [TotalExpense, setTotalExpense] = useState(0);
    const [TotalGuests,setTotalGuests] = useState(0);
    const [TotalGuestWithPledges,setTotalGuestWithPledges] = useState(0);
    const [TotalGuestWithContribution, setTotalGuestWithContribution] = useState(0);
    const [attandees, setattandees] = useState(0)
    const [ServiceProviderObject, setServiceProviderObject] = useState<ServiceProvider>()

    const [PackageName, setPackageName] = useState("");
    const [PackagePrice, setPackagePrice] = useState<number>()
    const [PackageDescription, setPackageDescription] = useState("")
    const [PackageCategory, setPackageCategory] = useState<number>()



    const [showToast, setshowToast] = useState(false);
    const [toastDescription, settoastDescription] = useState("");
    const [operationStatus, setoperationStatus] = useState(false);
    const [isloading, setisloading] = useState(false)

    const [spName, setspName] = useState("")
    // const [spLocation, setspLocation] = useState("")
    const [spEmail, setspEmail] = useState("")
    const [spPhone, setspPhone] = useState("")
    const [spDescription, setspDescription] = useState("")
    // const [spPhotos, setspPhotos] = useState<Photo[]>()
    const [wallpaper, setwallpaper] = useState("")
    let config = {
        headers: {
            uid: eventId
        }
    };

    


    useEffect(() => {
        let config = {
            headers: {
                uid: eventId
            }
        };
        const GetServiceProviderProfilePicture = async () =>{
            await ApiClient.get('sp_photos').then(
                (response)=>{
                   if(response.data !== ""){
                    let photsArray = response.data.data.map((img:Photo)=>new Photo(img))
                    photsArray.forEach((element:Photo) => {
                        if(element.sp_photo_category === 1){
                            setwallpaper(element.medium)
                            
                        }
                    });
                   }
                    // setspPhotos(photsArray)
                }
            ).catch((error)=>{
                console.log("error",error)
            })
        }
        const GetServiceProviderProfile = async ()=>{
            await ApiClient.get('sp/dashboard').then(
                (response)=>{
                    
                    setServiceProviderObject(new ServiceProvider(response.data))
                    
                }
            ).catch(
                (error)=>{
                    console.log("Error >> ",error)
                }
            )
        }
        // const getPledgeData = async () =>{
        //     let total:number = 0;
        //     await ApiClient.get('pledges',config
        //     ).then((response)=>{
        //        if(response.data !== ""){
        //         let pledge_array:Pledge[] = response.data.data.map((item:any)=>new Pledge(item));
        //         console.log('ds',pledge_array);
        //         pledge_array.forEach((item:Pledge)=>{
        //             total = +item.amount + total;
        //         })
        //         setTotalPledge(total)
        //        }
        //     }
        //     ).catch((error)=>{
        //         console.log("error >> ",error)
        //     })
        // }
        
        // const getBudgetsData = async ()=>{
        //     let total:number = 0;
        //     let totalCollected:number = 0;
        //     await ApiClient.get('budget',config
        //     ).then((response)=>{
        //         let budget_array:Budget[] = response.data.data.map((budgetitem:any)=>new Budget(budgetitem));
        //         budget_array.forEach((item:Budget)=>{
        //             total = +item.amount + total;
        //             totalCollected = +item.amount_paid+totalCollected;
        //         })
        //         setTotalBudget(total);
        //         setTotalCollectedBudget(totalCollected);


        //         console.log("wati",totalCollected);

        //     }   
        //     ).catch((error)=>{
        //         console.log("error >> ",error);
        //     })

            
            
        // }

        // const getFundData = async ()=>{
        //     let total:number = 0;
        //     await ApiClient.get('funds',config
        //     ).then((response)=>{
        //        if(response.data !== ""){
        //         let budget_array:Fund[] = response.data.data.map((funditem:any)=>new Budget(funditem));
        //         budget_array.forEach((item:Fund)=>{
        //             total = +item.amount + total;
        //         })
        //         setTotalFund(total);
        //        }
        //     }   
        //     ).catch((error)=>{
        //         console.log("error >> ",error);
        //     })
        // }

        // const getExpenseData = async ()=>{
        //     let total:number = 0;
        //     await ApiClient.get('expenditures',config
        //     ).then((response)=>{
        //       if(response.data !== ""){
        //         let budget_array:Expense[] = response.data.data.map((funditem:any)=>new Expense(funditem));
        //         budget_array.forEach((item:Expense)=>{
        //             total = +item.amount + total;
        //         })
        //         setTotalExpense(total);
        //         console.log("matu",total);
        //       }
        //     }   
        //     ).catch((error)=>{
        //         console.log("error >> ",error);
        //     })
        // }

        const getEventStats = async ()=>{
            await ApiClient.get('stats',config).then(
                (response)=>{
                    setTotalBudget(+response.data.data.budget_estimated);
                    setTotalPledge(+response.data.data.pledges)
                    setTotalFund(+response.data.data.funds_collected)
                    setTotalExpense(+response.data.data.expenditures)
                    setTotalGuests(+response.data.data.guests)
                    setTotalGuestWithPledges(+response.data.data.totalplegders)
                    setTotalGuestWithContribution(+response.data.data.totalfunders)
                    setattandees(+response.data.data.attendees)
                    setTotalCollectedBudget(+response.data.data.fundsvsbudget)
                }
            ).catch(error=>console.log("Error",error))
        }

        // const getGuestListCount = async ()=>{
        //     let total:number = 0;
        //     let totalGuestWithContribution:number = 0;
        //     await ApiClient.get('guest_list',config
        //     ).then((response)=>{
        //         let guestlist_array:Guest[] = response.data.data.map((funditem:any)=>new Guest(funditem));
        //         guestlist_array.forEach((item:Guest)=>{
        //             if(item.pledges != null){
        //                 total +=1;
        //                 if(item.pledges.amount_collected !== null || item.pledges.amount_collected !== "0.00"){
        //                     if(+item.pledges.amount_collected !== 0){
        //                     totalGuestWithContribution +=1;
        //                     }
        //                 }
        //             }
        //             //    total = +item.p + total;
        //         })
        //         setTotalGuestWithContribution(totalGuestWithContribution);
        //         setTotalGuestWithPledges(total);
        //         setTotalGuests(guestlist_array.length);
        //     }   
        //     ).catch((error)=>{
        //         console.log("error >> ",error);
        //     })
        // }




        
       if(userDataObj?.user_data.role === 1){
           getEventStats()
        // getBudgetsData().then(
        //     ()=>{
        //         getPledgeData().then(
        //             ()=>{
        //                 getFundData().then(
        //                     ()=>{
        //                         getExpenseData().then(
        //                             ()=>{
        //                                 getGuestListCount();
        //                             }
        //                         );
        //                     }
        //                 );
        //             }
        //         );
        //     }
        // );
       }
       if(userDataObj?.user_data.role === 3){
           GetServiceProviderProfile().then(()=>{
            GetServiceProviderProfilePicture().then(
                ()=>{
                   
                }
            )
           })
       }

    //    return (()=>{
    //     //    setServiceProviderObject(new ServiceProvider(undefined))
    //     console.log("returnning")
    //    })
        
      
    }, [eventId, userDataObj?.user_data.role]);



    const handleCloseClick = ()=>{
        setshowToast(!showToast);
    }




//     category_id: 2
// description: "Soda za bonite.\nBei ni kwa kila chupa moja"
// package_name: "Test"
// price: "1000"

const CreateNewPackage = ()=>{
    setisloading(true)
     ApiClient.post('sp/packages',
     {
         category_id:PackageCategory,
         description:PackageDescription,
         package_name:PackageName,
         price:PackagePrice
     },
     config
     ).then((response)=>{
        setisloading(false);
        setoperationStatus(true);
        setshowToast(true);
        settoastDescription("Item Saved")
        history.push('/dashboard')
     }).catch((error)=>{
        setisloading(false);
        setoperationStatus(false);
        setshowToast(true);
        settoastDescription("An error occured")
         console.log("error >> ",error)
     })
}

const DeletePackage = (id:number)=>{
    setisloading(true);
    ApiClient.delete(`sp/packages/${id}`,config).then((response)=>{
        console.log("item deleted",response.data)
        setisloading(false);
        setoperationStatus(true);
        setshowToast(true);
        settoastDescription("Item Update")
        history.push('/dashboard')
    }).catch((error)=>{
        console.log("Error",error)
        setisloading(false);
        setoperationStatus(false);
        setshowToast(true);
        settoastDescription("An error occured")
    })
}

const UpdatePackage = (id:number)=>{
    setisloading(true)
     ApiClient.put(`sp/packages/${id}`,
     {
         category_id:PackageCategory,
         package_description:PackageDescription,
         package_name:PackageName,
         price:PackagePrice
     },
     config
     ).then((response)=>{
        setisloading(false);
        setoperationStatus(true);
        setshowToast(true);
        settoastDescription("Item Update")
        history.push('/dashboard')
     }).catch((error)=>{
        setisloading(false);
        setoperationStatus(false);
        setshowToast(true);
        settoastDescription("An error occured")
         console.log("error >> ",error)
     })
}


const updateServiceProvider = () =>{
    if(spName !== "" && spPhone !== "" && SpDescription !== "" && spEmail !== ""){
        setisloading(true)
    ApiClient.put('sp/profile',{
        categories:ServiceProviderObject?.categories,
        description: spDescription,
        email: spEmail,
        location: ServiceProviderObject?.location,
        mobile: spPhone,
        name: spName,
        id:ServiceProviderObject?.id !== null ? ServiceProviderObject?.id : 1
    },config).then((response)=>{

        setisloading(false);
        setoperationStatus(true);
        setshowToast(true);
        settoastDescription("Profile Updated")
        history.push('/dashboard')

    }).catch((error)=>{
        setisloading(false);
        setoperationStatus(false);
        setshowToast(true);
        settoastDescription("An error occured")
    })
    }else{
        alert("all fields are required")
    } 
}


   

   
console.log("this user ",userDataObj?.user_data.role === 1 )
    return (
        
        <>
        <Helmet>
            <title>Admin Dashboard</title>
            <meta name="description" content="Administration Dashboard" />
        </Helmet>
        {userDataObj?.user_data.role === 1 ? 
        <HomeSectionHolder>
            <SectionCardHolder>
            <SectionTitle>
            Budget Statistics
            </SectionTitle>
            <TopSectionHolder>
                <IndividualCard color="#e8eaf6">
                    <IndividualCardTitle>
                        Total Budget
                    </IndividualCardTitle>
                    <MoneyBillWave></MoneyBillWave>
                    <IndividualCardAmount color="#4527a0">{NumberFormatter.format(TotalBudget)}</IndividualCardAmount>
                </IndividualCard>
                <IndividualCard color="#fff3e0">
                    <IndividualCardTitle>
                        Pledges
                    </IndividualCardTitle>
                    <TakeMyMoney/>
                    <IndividualCardAmount color="#f57c00">{NumberFormatter.format(TotalPledge)}</IndividualCardAmount>
                </IndividualCard>
                <IndividualCard color="#f1f8e9">
                    <IndividualCardTitle>
                        Cash Collected
                    </IndividualCardTitle>
                    <Cash/>
                    <IndividualCardAmount color="#388e3c">{NumberFormatter.format(TotalFund)}</IndividualCardAmount>
                </IndividualCard>
        
                </TopSectionHolder>
                
            </SectionCardHolder>

         
            
            <ChartSectionHolder>
            <ProgressChart y={TotalCollectedBudget} title="Budget Collection"/>
            <ListingCardContainer>
            <ChartTitle>Event Statistics</ChartTitle> 
                <ListingContents>
                    <ListItem>
                        Budget <ListItemAMount>{NumberFormatter.format(TotalBudget)}</ListItemAMount>
                    </ListItem>
                    <ListItem>
                        Ahadi <ListItemAMount>{NumberFormatter.format(TotalPledge)}</ListItemAMount>
                    </ListItem>
                    <ListItem>
                        Pesa Taslimu <ListItemAMount>{NumberFormatter.format(TotalFund)}</ListItemAMount>
                    </ListItem>
                    <ListItem>
                        Matumizi <ListItemAMount>{NumberFormatter.format(TotalExpense)}</ListItemAMount>
                    </ListItem>
                </ListingContents>
            </ListingCardContainer>
            <ListingCardContainer>
            <ChartTitle>Guest Statistics</ChartTitle> 
                <ListingContents>
                    <ListItem>
                        Orodha Ya Wageni <ListItemAMount> {TotalGuests}</ListItemAMount>
                    </ListItem>
                    <ListItem>
                        Walio Ahidi <ListItemAMount>{TotalGuestWithPledges}</ListItemAMount>
                    </ListItem>
                    <ListItem>
                        Waliochangia <ListItemAMount>{TotalGuestWithContribution}</ListItemAMount>
                    </ListItem>
                    <ListItem>
                        Wataohudhuria <ListItemAMount>{attandees}</ListItemAMount>
                    </ListItem>
                </ListingContents>
            </ListingCardContainer>


            
            </ChartSectionHolder>
                  
         
           
      
        </HomeSectionHolder> 
        :
        
         <HomeSectionHolder>
            
            <SectionTitle>
                Service provider Dashboard
            </SectionTitle>
            <SpSectionHolder>
                <SpUserCard>
                    <SpNameContainer>
                    <SpProfile src={wallpaper !== undefined ? wallpaper : "https://www.harusi.online/img/logo.png"}/> 
                    <SpProfileText>{ServiceProviderObject?.name}</SpProfileText>
                    <SpProfileText>{ServiceProviderObject?.location}</SpProfileText>
                    <SpProfileText>{ServiceProviderObject?.mobile}</SpProfileText>
                    <SpProfileText>{ServiceProviderObject?.email}</SpProfileText>
                    </SpNameContainer> 
                    <SpDescriptionContainer>
                    <SpCategoryContainer>
                        {ServiceProviderObject?.categories?.map((cat:Category,index)=>{
                            return(
                                <SpCategory key={index}>{cat.label}</SpCategory>
                            );
                        })}
                       
                        </SpCategoryContainer>
                        <SpDescription>
                        {ServiceProviderObject?.description}
                        </SpDescription>
                       
                        <Popup onOpen={()=>{
                            if(ServiceProviderObject !== undefined){
                                setspName(ServiceProviderObject?.name)
                                setspEmail(ServiceProviderObject.email)
                                setspPhone(ServiceProviderObject.mobile)
                                setspDescription(ServiceProviderObject.description)
                            }
                        }} trigger={<SpEditButton>Edit</SpEditButton>} modal>
                            <EditModalContainer>
                            {isloading && <EditLoaderOverlay><GlobalProgressLoader isVisible={true} width={30} height={30}/></EditLoaderOverlay>}
                            <EditTopSection>
                                <GuestEditHeader>
                                    Profile edit
                                </GuestEditHeader>
                                </EditTopSection>
                                <MiddleSection>
                                    <form onSubmit={(event:any)=>{
                                        event.preventDefault();
                                        updateServiceProvider();
                                    }}>
                                        <AppFormInput type="text" required  defaultValue={spName} onChange={(val:any)=>setspName(val.currentTarget.value)} placeholder="Display name"/>
                                        <AppFormInput type="email" required defaultValue={spEmail} onChange={(e:any)=>setspEmail(e.currentTarget.value)} placeholder="Display email"/>
                                        <AppFormInput type="phone" required defaultValue={spPhone} onChange={(e:any)=>setspPhone(e.currentTarget.value)} placeholder="Phone number"/>
                                        <AppFormInput type="text"required defaultValue={spDescription} onChange={(e:any)=>setspDescription(e.currentTarget.value)} placeholder="Description"/>
                                        {/* <FormInputSelect>
                                            <option>Location</option>
                                        </FormInputSelect> */}
                                     
                                     <AppSuccessButton type="submit">Update</AppSuccessButton>
                                    </form>
                                    
                                </MiddleSection>
                                
                            </EditModalContainer>
                        </Popup>
                    </SpDescriptionContainer>
                </SpUserCard>
                <SpPackagesSection>
                 <SpPackageHeaderContainer>
                 <p>Packages</p>
                    <Popup modal trigger={<div><SpPackageAdd/></div>}>
                        <EditModalContainer>
                        <EditTopSection>
                        {isloading && <EditLoaderOverlay><GlobalProgressLoader isVisible={true} width={30} height={30}/></EditLoaderOverlay>}
                                <GuestEditHeader>
                                    New Service Package
                                </GuestEditHeader>
                                </EditTopSection>
                                <MiddleSection onSubmit={(e)=>{
                                    e.preventDefault()
                                    CreateNewPackage()
                                }}>
                                    <form>
                                        <AppFormInput type="text" value={PackageName} onChange={(e)=>{setPackageName(e.currentTarget.value)}}  placeholder="Package Display name"/>
                                        <AppFormInput type="number" value={PackagePrice} onChange={(e)=>{setPackagePrice(+e.currentTarget.value)}}  placeholder="Package Display price"/>
                                        <AppFormInput type="text" value={PackageDescription} onChange={(e)=>{setPackageDescription(e.currentTarget.value)}}   placeholder="Package Description"/>
                                        <FormInputSelect value={PackageCategory} onChange={(e)=>{
                                            setPackageCategory(+e.currentTarget.value)
                                        }}>
                                            <option>Category</option>
                                            {ServiceProviderObject?.categories?.map((cat:Category)=><option value={cat.id}>{cat.label}</option>)}
                                        </FormInputSelect>
                                        <AppSuccessButton>Update</AppSuccessButton>
                                        
                                    </form>
                                   
                                </MiddleSection>
                        </EditModalContainer>
                    </Popup>
                 </SpPackageHeaderContainer>
                 {ServiceProviderObject?.packages?.map((p,index)=>{
                     return(
                        <Popup modal onOpen={()=>{
                            setPackageName(p.package_name)
                            setPackagePrice(p.price)
                            setPackageDescription(p.package_description)
                            setPackageCategory(p.category_id)
                        }} trigger={ 
                            <PackageWrapper>
                            <SpPackage   key={index} title={p.package_name} desc={p.package_description} price={NumberFormatter.format(p.price)} />
                            </PackageWrapper>
                                }>

<EditModalContainer>
                        <EditTopSection>
                        {isloading && <EditLoaderOverlay><GlobalProgressLoader isVisible={true} width={30} height={30}/></EditLoaderOverlay>}
                     <GuestEditHeader>
                                    Edit Service Package
                                </GuestEditHeader>
                                </EditTopSection>
                                <MiddleSection onSubmit={(e)=>{
                                    e.preventDefault()
                                }}>
                                    <form>
                                        <AppFormInput type="text" defaultValue={p.package_name} onChange={(e)=>{setPackageName(e.currentTarget.value)}}  placeholder="Package Display name"/>
                                        <AppFormInput type="number" defaultValue={p.price} onChange={(e)=>{setPackagePrice(+e.currentTarget.value)}}  placeholder="Package Display price"/>
                                        <AppFormInput type="text" defaultValue={p.package_description} onChange={(e)=>{setPackageDescription(e.currentTarget.value)}}   placeholder="Package Description"/>
                                        <FormInputSelect value={p.category_id} onChange={(e)=>{
                                            setPackageCategory(+e.currentTarget.value)
                                        }}>
                                            <option>Category</option>
                                            {ServiceProviderObject?.categories?.map((cat:Category)=><option value={cat.id}>{cat.label}</option>)}
                                        </FormInputSelect>
                                        <div>
                                        <AppSuccessButton onClick={()=>UpdatePackage(p.id)}>Update</AppSuccessButton>
                                        <p></p>
                                        <AppGlobalDeleteButton onClick={()=>{DeletePackage(p.id)}}>Delete Package</AppGlobalDeleteButton>
                                        </div>
                                    </form>
                                   
                                </MiddleSection>
                        </EditModalContainer>

                                </Popup>

                 )})}
                  
                </SpPackagesSection>
            </SpSectionHolder>
            {showToast && <Toast closeClick={handleCloseClick} description={toastDescription} type={operationStatus ? 'Sucess' : 'Error'}/>}
            </HomeSectionHolder>}

            {/* <Popup modal open={true}>
            <>
                     <EditModalContainer>
                        <EditTopSection>
                        {isloading && <EditLoaderOverlay><GlobalProgressLoader isVisible={true} width={30} height={30}/></EditLoaderOverlay>}
                     <GuestEditHeader>
                                    Edit Service Package
                                </GuestEditHeader>
                                </EditTopSection>
                                <MiddleSection onSubmit={(e)=>{
                                    e.preventDefault()
                                    CreateNewPackage()
                                }}>
                                    <form>
                                        <AppFormInput type="text" value={p.package_name} onChange={(e)=>{setPackageName(e.currentTarget.value)}}  placeholder="Package Display name"/>
                                        <AppFormInput type="number" value={p.price} onChange={(e)=>{setPackagePrice(e.currentTarget.value)}}  placeholder="Package Display price"/>
                                        <AppFormInput type="text" value={p.package_description} onChange={(e)=>{setPackageDescription(e.currentTarget.value)}}   placeholder="Package Description"/>
                                        <FormInputSelect value={p.category_id} onChange={(e)=>{
                                            setPackageCategory(e.currentTarget.value)
                                        }}>
                                            <option>Category</option>
                                            {ServiceProviderObject?.categories?.map((cat:Category)=><option value={cat.id}>{cat.label}</option>)}
                                        </FormInputSelect>
                                        <AppSuccessButton>Update</AppSuccessButton>
                                        <AppGlobalDeleteButton>Delete Package</AppGlobalDeleteButton>
                                    </form>
                                   
                                </MiddleSection>
                        </EditModalContainer>
                     </>
            </Popup> */}
        </>
    )
}

export default MainDashboard
