
import React from 'react'
import InputField from '../Textfield/TextField';

type step1Data = {
    namechangeListener:(value:any) => void;
    emailchangeListener:(value:any) => void;
    phonechangeListener:(value:any) => void;
    nameValue:any;
    phoneValue:any;
    emailValue:any;
}


export const PledgeStep1:React.FC<step1Data> = ({namechangeListener,emailchangeListener,phonechangeListener,nameValue,phoneValue,emailValue})=>{
    const handleEmailChange=(val:any) => {emailchangeListener(val)};
    const handleNameChange = (val:any) => {namechangeListener(val)};
    const handlePhoneChange = (val:any) => {phonechangeListener(val)};
        return(
                <section>
                    <InputField isRequired type="text"  label="Your Name" onChange={handleNameChange} value = {nameValue}/>
                    <InputField isRequired type="email" label="Your Email" onChange={handleEmailChange} value = {emailValue}/>
                    <InputField isRequired type="text" label="Phone number"  onChange={handlePhoneChange} value = {phoneValue} maxInput={10}/>
                </section>
        );
}

type step2Data = {
    attendingChangeListener:(value:any)=>void;
    isAttending:boolean;
    pledgeAmountChangeListener:(value:any)=>void;
    pledgeAmount:any;
}

export const PledgeStep2:React.FC<step2Data> = ({isAttending,pledgeAmountChangeListener,pledgeAmount,attendingChangeListener})=>{
    const handleAmountChange=(val:any) => {pledgeAmountChangeListener(val)};
    const handleAttendinglistener = (val:any) => {attendingChangeListener(val)};
 
        return(
                <section>
                    <InputField type="number" label="Your pledge amount" onChange={handleAmountChange} value = {pledgeAmount}/>


                    <p>Will you be able to join us at our ceremony ?</p>
                    <label>Yes
                    <input checked={isAttending} onChange={(e)=>handleAttendinglistener(e.currentTarget.value)} type="radio" value="true" id="attend" />
                         </label>
                   <label>
                       No
                       <input checked={!isAttending} onChange={(e)=>handleAttendinglistener(e.currentTarget.value)} type="radio" value="false" id="no-attend" />
                   </label>
            </section>
        );
}