import React from 'react'
import { useState } from 'react'
import Popup from 'reactjs-popup'
import {  ProgressChartSM, Toast } from '..'
import { ApiClient } from '../../Data/ApiClient'
import { Budget } from '../../Data/Classes/Budget'
import { Category } from '../../Data/Classes/Category'
import { AppFormInput, AppGlobalButton, AppGlobalDeleteButton, ButtonContainer, InputUnit } from '../../pages/UserLogin/Login.elements'
import { NumberFormatter } from '../Common/Config'
import GlobalProgressLoader from '../Common/GlobalLoader'
import { BudgetModalContainer } from '../DashboardPages/Budget/Budget.elements'
import { EditLoaderOverlay, EditTopSection, GuestEditHeader, GuestMiddleSectionHeader, MiddleSection, PledgeDescriptionWrapper } from '../Modal/Modal.elements'
import { BudgetCardItem, CardBody, CardChartContainer, CardDesc, CardPrice, CardTextContainer, CardTitle, CategorySelector, LeftLine, RightCardContent } from './BudgetCard.elements'

type cardOptions = {
    budgetItem:Budget;
    categories:Category[];
    eventid:string;
}

const BudgetCard:React.FC<cardOptions>=({budgetItem,categories,eventid})=>{
    const [closemodal, setclosemodal] = useState(false)
    const [isloading, setisloading] = useState(false)
    const [Category, setCategory] = useState<number>()
    const [Amount, setAmount] = useState(0)
    const [Desc, setDesc] = useState("")
    const [ExponseAmount, setExponseAmount] = useState(0)
    const [ExponseDescription, setExponseDescription] = useState("")
    const [showToast, setshowToast] = useState(false);
    const [toastDescription, settoastDescription] = useState("");
    const [operationStatus, setoperationStatus] = useState(false);
    const [selectedItem, setselectedItem] = useState(0)

    let config = {
        headers: {
            uid: eventid
        }
    };

    const handleCloseClick = ()=>{
        setshowToast(!showToast);
    }

    const AddExpense = ()=>{
        ApiClient.post('expenditures',{
            amount:ExponseAmount,
            description:ExponseDescription,
            budget_id:selectedItem
        },config).then(
        (response)=>{
            setisloading(false)
                setoperationStatus(true)
                settoastDescription('Expense added')
                setshowToast(true)
        }
        ).catch(
            (error)=>{
                setisloading(false)
                console.log("error :: ",error)
                setoperationStatus(false)
                settoastDescription('An error occured')
                setshowToast(true)
            }
        )
    }

    const DeleteBudgetItem = async () =>{
        setisloading(true)
        ApiClient.delete('budget/'+selectedItem,config).then((response)=>{
            setisloading(false)
            setoperationStatus(true)
            settoastDescription('Item deleted')
            setshowToast(true)
        }).catch((error)=>{
            setisloading(false)
            console.log("error :: ",error)
            setoperationStatus(false)
            settoastDescription('An error occured')
            setshowToast(true)
        })
    }

    const updateBudget = ()=>{
        ApiClient.put('budget/'+selectedItem,{
            amount:Amount,
            description:Desc,
            eventid:budgetItem.event_id,
            id:selectedItem,
            service_category_id : Category

        },config
        ).then(
            (response)=>{
                setisloading(false)
                setoperationStatus(true)
                settoastDescription('Item updated')
                setshowToast(true)
            }
        ).catch(
            (error)=>{
                setisloading(false)
                console.log("error :: ",error)
                setoperationStatus(false)
                settoastDescription('An error occured')
                setshowToast(true)
            }
        )
    }
    const handleEditFormSubmit = (event:any) =>{
        event.preventDefault();
        setisloading(true)


        if(ExponseAmount > 0){
            AddExpense()
        }else{
            updateBudget()
        }



    }

   
    
    return (
        <Popup open={closemodal} onOpen={()=>{
            setselectedItem(budgetItem.id)
            setCategory(budgetItem.service_category_id)
        }} modal trigger={
            <BudgetCardItem>
            <LeftLine/>
            <RightCardContent>
            <CardTitle>
                {budgetItem.item}
            </CardTitle>
            <CardBody>
                <CardChartContainer>
                    <ProgressChartSM y={(+budgetItem.amount_paid*100)/+budgetItem.amount}/>
                </CardChartContainer>
                <CardTextContainer>
                    <CardPrice>
                        {NumberFormatter.format(+budgetItem.amount)}
                    </CardPrice>
                    <CardDesc>
                        {budgetItem.description}
                    </CardDesc>
                </CardTextContainer>
            </CardBody>
            </RightCardContent>
           
        </BudgetCardItem>
        }>
            <BudgetModalContainer>
            {isloading && <EditLoaderOverlay><GlobalProgressLoader isVisible={true} width={30} height={30}/></EditLoaderOverlay>}
                                <EditTopSection>
                                <GuestEditHeader>
                                    Edit budget item
                                </GuestEditHeader>
                                </EditTopSection>

                                <MiddleSection>
                                    <form onSubmit={
                                        handleEditFormSubmit}>
                                        <InputUnit>
                                        <CategorySelector onChange={(val:any)=>setCategory(val.currentTarget.value)}>
                                            <option>Budget Category</option>
                                           {categories.map((cat,index)=><option selected={budgetItem.service_category_id === cat.id ? true : false} key={index} value={budgetItem.service_category_id}>{cat.label}</option>)}
                                        </CategorySelector>
                                        </InputUnit>
                                        <InputUnit>
                                        <AppFormInput onChange={(val:any)=>setAmount(val.currentTarget.value)} defaultValue={budgetItem.amount} type="number" placeholder="Amount"/>
                                        </InputUnit>
                                        <InputUnit>
                                        <PledgeDescriptionWrapper onChange={(val:any)=>setDesc(val.currentTarget.value)} defaultValue={budgetItem.description} placeholder="Budget description"/>
                                        </InputUnit>
                                        <GuestMiddleSectionHeader>
                                    Add Expenditure
                                        </GuestMiddleSectionHeader>
                                        <InputUnit>
                                        <AppFormInput onChange={(val:any)=>setExponseAmount(val.currentTarget.value)}  type="number" placeholder="Amount"/>
                                        </InputUnit>
                                        <InputUnit>
                                        <PledgeDescriptionWrapper onChange={(val:any)=>setExponseDescription(val.currentTarget.value)} placeholder="Budget description"/>
                                        </InputUnit>

                                        <ButtonContainer>
                                            <AppGlobalButton formAction="submit">Save</AppGlobalButton>
                                            <AppGlobalDeleteButton  onClick={
                                        (e:any)=>{
                                         e.preventDefault();
                                            DeleteBudgetItem().then(()=>{
                                                setclosemodal(true)
                                            })
                                        }
                                    }>Delete</AppGlobalDeleteButton>
                                        </ButtonContainer>
                                        </form>
                                </MiddleSection>
            </BudgetModalContainer>
            {showToast && <Toast closeClick={handleCloseClick} description={toastDescription} type={operationStatus ? 'Sucess' : 'Error'}/>}
        </Popup>
    )
}

export default BudgetCard
