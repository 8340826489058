import styled from 'styled-components';

export const LeftDiv = styled.div`
    float:left;
    width:50%;
    height:100%;
    @media screen and (max-width: 960px) {
    width:100%;
  }
    `;
export const OverFlow = styled.div`
  overflow: hidden;

`;

export const DivSlider = styled.div`
  white-space: nowrap;
  transition: transform 0.3s;
`;

export const CorouselItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  white-space: normal;
`;

export const LeftDivContentContainer = styled.div`
 display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;

`;
export const RightDiv = styled.div`
    float:right;
    width:50%;
    height:100%;
    display: grid;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 960px) {
    display: none;
  }
    `;
export const DivHolder = styled.div`
    padding: 0px 20px 0px 20px;
    display:flex;
    align-items:center;
    @media screen and (max-width: 960px) {
      /* background: red; */
      display: flex;
  }
  
`;

export const HeroImg = styled.img`
width: 500px;
height: 500px;
    /* float:left; */
`;

export const HeadLine = styled.h1`
font-size: 74px;
    font-weight: 600;
    margin-bottom:0;
    line-height: 92px;
    color:#24366b;

    @media screen and (max-width: 960px) {
    font-size: 55px;
    font-weight: 600;
    line-height: 60px;
  }
`;

export const CTA = styled.span`
  font-weight:600;
  color:#24366b;;
  font-size: 19px;
  line-height: 10;

`;

export const Subtitle = styled.p`
  
  margin-top:5;
  font-size: 16px;
  line-height: 24px;
  font-weight:normal;
  color:#727ea3;
  text-align:justify;

  @media screen and (max-width: 960px) {
    font-size: 16px;
    line-height: 24px;
  }

`;

