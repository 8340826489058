import styled, { keyframes } from "styled-components"

const toastin = keyframes`
from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
`;

export const NotificationContainer = styled.div`
    font-size: 14px;
	box-sizing: border-box;
	position: fixed;
	z-index: 999999;
    top: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: ${toastin} .7s;
    
`;

type notificationProps = {
    background:string;
}



export const Notification = styled.div<notificationProps>`
    background: #fff;
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
	margin-bottom: 15px;
    height: 40px;
	width: 300px;
    padding: 5px;
    max-height: 40px;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 0 10px #999;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;
    background-color: ${(p:notificationProps)=>p.background};
`;

export const ToastElement = styled.div`
    width: 100%;
    /* height: 100%; */
	color: #fff;
    padding: 5px;
    flex-direction: row;
    justify-content: space-evenly;
    /* align-items: baseline; */
    /* margin: 10px; */
	/* padding: 20px 15px 10px 10px; */
    display: flex;
`;


export const NotificationImage = styled.img`
     float: left;
    margin-right: 15px;
    width: 30px;
    height: 30px;
`;

export const NotificationTitle = styled.p`
font-weight: 500;
	font-size: 16px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 6px;
    width: 300px;
    line-height: 10px;
`;

export const NotificationMessage  = styled.p`
    margin: 0;
	text-align: left;
    line-height: 16px;
    margin-left: -1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const NotificationTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
`;

export const NotificationCanceButton = styled.button`
    position: relative;
	/* right: -.3em;
	top: -.3em; */
	float: right;
	font-weight: 100;
	color: #fff;
	outline: none;
	border: none;
	text-shadow: 0 1px 0 #fff;
	opacity: .8;
	line-height: 1;
	font-size: 16px;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0
`;





