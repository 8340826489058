import React from 'react'
import { AiOutlineSearch } from 'react-icons/ai';
import { SearchInputContainer } from './Textfield.elemets';

type reqType = 'sp'|'event'|'all';

type searchInputData = {
    value?:string;
    onChange: (str:any) => void;
    clickListener:() => void;
    displayType:reqType
  }
  
  
  const SearchInputField:React.FC<searchInputData> = ({onChange,value,clickListener,displayType}) =>{
  
    const handleOnChange = (val: any) => {
      // validateValue(val)
      onChange(val)
    }

    const handleSearchClick = () =>{
        clickListener();
    }
   
    return(
        <SearchInputContainer>
            
        <input type="text"  value = {value}   onChange = {e => handleOnChange(e.target.value)} placeholder={displayType === 'sp' ? "Tafuta watoa-huduma" : displayType === 'event' ? "Tafuta shughuli" : "Tafuta watoa-huduma"} />
  
        <button onClick={handleSearchClick}>
        <AiOutlineSearch/>
        </button>
  
        </SearchInputContainer>
    );
  };



export default SearchInputField
