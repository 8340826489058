import React from 'react'
import {VictoryPie} from 'victory'
import { ChartTitle, ProgressChartHolder } from './ProgressChart.elements'


type progressOptions = {
    y:number;
    title:string;
}

const ProgressChart:React.FC<progressOptions> = ({y,title}) =>{
    return (
        <ProgressChartHolder>
           <ChartTitle>{title}</ChartTitle> 
        {/* <svg  width={200} height={200}>
        <text x={100} y={110} textAnchor="middle" >
            {y}%
        </text> */}
        <svg width={300} height={250}>
        <text x={150} y={150} textAnchor="middle" >
            {y.toFixed(2)}%
        </text> 
        
        <VictoryPie 
        padAngle={0}
        labelComponent={<span/>}
        animate={{duration:1000}}
        standalone={false}
        innerRadius={70}
        width={300} height={300}
        colorScale={["#06f", "#EEEEEE" ]}
        data={[{'key': "uuu", 'y': (100-y)}, {'key': "", 'y': y} ]}>
        </VictoryPie>
  
       
        </svg>
       </ProgressChartHolder>     
       
    )
}

export default ProgressChart
