

export class Package{
    category: string
    id: number
    sp_id: number
    package_name: any
    package_description: any
    price: number
    created_by: number
    date_created: any
    status: number
    deleted_at: any
    created_at: any
    updated_at: any
    category_id: number


    constructor(spackage:any){
        this.id = spackage.id;
        this.category = spackage.category;
        this.sp_id = spackage.sp_id;
        this.package_name = spackage.package_name;
        this.package_description = spackage.package_description;
        this.price = spackage.price;
        this.created_by = spackage.created_by;
        this.date_created = spackage.date_created;
        this.status = spackage.status;
        this.deleted_at = spackage.deleted_at;
        this.created_at = spackage.created_at;
        this.updated_at = spackage.updated_at
        this.category_id = spackage.category_id;
    }
}