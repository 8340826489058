export class Photo {
    id:number;
    path:string;
    caption:string;
    status: number;
    category: number;
    event_id:number;
    medium:string;
    small:string;
    large:string;
    sp_id:number;
    sp_photo_category:number;

    constructor(photo:any){
        this.id = photo !== undefined ? photo.id : undefined;
        this.path = photo !== undefined ? photo.photo_path : undefined;
        this.caption = photo !== undefined ? photo.caption : undefined;
        this.category = photo !== undefined ? photo.photo_category : undefined;
        this.event_id = photo !== undefined ? photo.event_id : undefined;
        this.status = photo !== undefined ? photo.status : undefined;
        this.medium = photo !== undefined ? photo.md_photo : undefined;
        this.small = photo !== undefined ? photo.sm_photo : undefined;
        this.large = photo !== undefined ? photo.lg_photo: undefined;
        this.sp_id = photo !== undefined ? photo.sp_id : undefined;
        this.sp_photo_category = photo !== undefined ? photo.sp_photo_category : undefined;
    }

   
}