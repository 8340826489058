import React from 'react'

import { FeatureItem, Heading, Subtitle } from './Body.elements'


interface feat {
    icon? : string,
    title: String,
    desc: String
}


function Feature(features : feat)  {

    // console.log("ss",features.icon.one);

    return (
       <>
            <FeatureItem>
            {/* {features.icon} */}
            <img alt="" src={features.icon} width="450px"/>
           <Heading>
               {features.title}
           </Heading>

           <Subtitle>
               {features.desc}
           </Subtitle>
            </FeatureItem>
           
             
       </>
    )
}

export default Feature
