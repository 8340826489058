import styled from "styled-components";

export const ServiceProviderCardItem = styled.div`
     cursor: pointer;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    /* background: tan; */
    width: 100%;
    height: 70px;
    margin: 5px;
    display:flex;
    flex-direction:row;
`;

export const PackageWrapper = styled.div`
    width: 90%;
`;