import React, { useEffect, useRef, useState } from 'react'
import { ApiClient } from '../../Data/ApiClient';
import { Event } from '../../Data/Classes/Event';
import { ServiceProvider } from '../../Data/Classes/Service_provider';
import Card from '../Card/Card';
import { CardWrapper } from '../Card/Card.elements';
import GlobalProgressLoader from '../Common/GlobalLoader';
import { AiOutlineLeftIcon, AiOutlineRightIcon, BodyLeftRightIconsHolder, DivHolder, FeaturedServiceProvidersWrapper, FeaturesHolder, ProgressHolder, SectionHeader, SectionHeaderEvents, ServiceProviderViewAll, UpcomingEvents } from './Body.elements'
import {data, data2, data3} from './Data';
import Feature from './Feature';



const Body:React.FC = ()=> {
    // let { path, url } = useRouteMatch();
    const [upcomingEvents,setUpcomingEvents] = useState<Event[]>([]);
    const [featuredServiceProvider,setFeaturedServiceProviders] = useState<ServiceProvider[]>([]);
    const [showLoader,setShowLoader] = useState(false);
    const [showSpLoader,setShowSpLoader] = useState(false);
    const spDivRef = useRef<any>(null);
    const eventDivRef = useRef<any>(null);
    const getUpcommingEvents = ():void =>{
        setShowLoader(true);
        ApiClient.get('eventsp').then((response)=>{
            let AllEventsObject = response.data;
            // Location[] = allLocationsArray.map((loc:any) => new Location(loc));
            let PassedEventsArray:Event[] = AllEventsObject.data.Npassed.map((event:any) => new Event(event));
            // console.log('success >>> ',PassedEventsArray)
            setUpcomingEvents(PassedEventsArray);
            setShowLoader(false);
        }).catch((error)=>{
            console.log('error',error)
        })
    }

    const getFeaturedSp = ():void =>{
        setShowSpLoader(true);
        ApiClient.get('service_providers').then((response)=>{
            let AllSpObject = response.data;
            // Location[] = allLocationsArray.map((loc:any) => new Location(loc));
            let serviceProviderArray:ServiceProvider[] = AllSpObject.map((sp:any) => new ServiceProvider(sp));
            // console.log('success >>> ',serviceProviderArray)
            // setUpcomingEvents(PassedEventsArray);
            setFeaturedServiceProviders(serviceProviderArray);
            setShowSpLoader(false);
        }).catch((error)=>{
            console.log('error',error)
        })
    }


    const spScroll = (scrollOffset:number) => {
        if(spDivRef.current !== null){
        spDivRef.current.scrollLeft += scrollOffset;
        }
      };



      const eventScroll = (scrollOffset:number) => {
        if(eventDivRef.current !== null){
        eventDivRef.current.scrollLeft += scrollOffset;
        }
      };


    useEffect(() => {
        const fetchData = async () => {
          getUpcommingEvents()
          getFeaturedSp()
          }

          fetchData()
          }
    , []);
    return (
        <DivHolder>
            {/* <SectionHeader>
                Tailor-made Features
                <SectionSubTitle>
                Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.
                </SectionSubTitle>
            </SectionHeader>

           <FeaturesHolder>
           <Feature {...data}/>
            <Feature {...data2}/>
            <Feature {...data3}/>
           </FeaturesHolder> */}

           <SectionHeader>
               Our Approach
           </SectionHeader>

           <FeaturesHolder>
           <Feature {...data}/>
            <Feature {...data2}/>
            <Feature {...data3}/>
           </FeaturesHolder>

           <UpcomingEvents>
           <SectionHeaderEvents>
               Upcoming Events
            <BodyLeftRightIconsHolder>
               <AiOutlineLeftIcon onClick={()=>eventScroll(-20)}/>
               <AiOutlineRightIcon onClick={()=>eventScroll(20)}/>
           </BodyLeftRightIconsHolder>
           </SectionHeaderEvents>
           <ProgressHolder>
           <GlobalProgressLoader isVisible={showLoader} width={50} height={50}/>
           </ProgressHolder>
           <CardWrapper ref={eventDivRef}>
            {upcomingEvents.length !== 0 && (
                
                upcomingEvents.map((event:Event,index)=>{
                    // console.log("called on >> ",event.wallpaper);
                   return(
                    <Card index={index} key={index} url={event.event_address} title={event.event_title} date={event.event_date} wallpaper_url={event.wallpaper![0] !== undefined ? event.wallpaper![0].medium : "https://api.harusi.online/img/default_cover.png"}/>
                   );
                   
                })

                // upcomingEvents.map((event:Event)=>{
                //     <h1>Hello, world!</h1>
                // })
            )}
         
          
          


           </CardWrapper>
           <ServiceProviderViewAll href="/all-events">View All</ServiceProviderViewAll>
           </UpcomingEvents>
           
           <FeaturedServiceProvidersWrapper>
           <SectionHeaderEvents>
               Featured Service Provider
               <BodyLeftRightIconsHolder>
               <AiOutlineLeftIcon onClick={()=>spScroll(-20)}/>
               <AiOutlineRightIcon onClick={()=>spScroll(20)}/>
           </BodyLeftRightIconsHolder>
           </SectionHeaderEvents>
           <ProgressHolder>
           <GlobalProgressLoader isVisible={showSpLoader} width={50} height={50}/>
           </ProgressHolder>
      
           <CardWrapper ref={spDivRef}>
                {/* {console.log("should render >> ",featuredServiceProvider[0])} */}
               {featuredServiceProvider.length !== 0 && (
                   featuredServiceProvider.map((sp:ServiceProvider,index)=>{

                   return(
                    index < 10 && (
                       
                    
                        <Card index={index} key={index}  url={sp.id} title={sp.name} date={sp.location} wallpaper_url={sp.wallpaper != null ? sp.wallpaper.medium : undefined} isSp={true}
                        spCategory = {sp.categories} 
                    />
                   
                    )
                   )
                       
                        
                   })
               )}
       
           
           </CardWrapper>
           <ServiceProviderViewAll href="/all-sp">View All</ServiceProviderViewAll>
           </FeaturedServiceProvidersWrapper>


          

        </DivHolder>
    )


    
}

export default Body

