import styled from 'styled-components';
import  {FaMagento, FaStackOverflow}  from 'react-icons/fa'
import { SiGraphcool } from "react-icons/si";
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

const primaryColor = "#4B59F7";

export const SignupHeader = styled.h2`
  
`;


export const DivHolder = styled.div`
 -webkit-flex-direction: column; 
  flex-direction: column; 
  display:flex;
  align-items:center;
  padding: 0px 20px 0px 20px;
`;


export const SectionHeader = styled.h3`
    font-weight:bold;
    font-size:48px;
    color:#24366b;
    text-align:center;
    @media screen and (max-width: 960px) {
    /* font-size:30px; */

  }
`;

export const BodyLeftRightIconsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AiOutlineRightIcon = styled(AiOutlineRight)`
font-size:20px;
color:${primaryColor};
cursor: pointer;
`;

export const AiOutlineLeftIcon = styled(AiOutlineLeft)`
font-size:20px;
color:${primaryColor};
cursor: pointer;
`;

export const SectionHeaderEvents = styled.h3`
    font-weight:bold;
    display: flex;
    justify-content: space-between;
    /* font-size:48px; */
    color:#24366b;
    @media screen and (max-width: 960px) {
    font-size:30px;

  }
`;

export const UpcomingEvents = styled.div`
    width:100%;
`;

export const FeaturedServiceProvidersWrapper = styled.div`
    width:100%;
`;


export const SectionSubTitle = styled.p`
    font-size:18px;
    text-align:center;
    font-weight:normal;
    max-width:500px;
    line-height: 24px;
`;

export const ServiceProviderViewAll = styled.a`
  color: #06f;
  width: 100%;
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    /* line-height: 29px; */
    display: flex;
    -webkit-align-items: center;
    align-items: center;
`;

export const ProgressHolder = styled.div`
display:flex;
justify-content:center;
`;

export const FeaturesHolder = styled.div`
 width:100%;
 display:flex;
 justify-content:space-around;
 flex-direction:row;

 margin-bottom: 10px;


 @media (max-width: 800px) {
  
    flex-direction: column;
  
}
 
`;

export const Subtitle = styled.p`
  max-width: 440px;
  /* margin-bottom: 35px; */
  font-size: 16px;
  font-weight:400;
  color: #727ea3;
  /* line-height: 24px; */
`;

export const Heading = styled.h3`
  margin-bottom: 0px;
  font-size: 20px;
  /* line-height: 1.1; */
  font-weight: 600;
  color: #24366b;
`;

export const FeatureItem = styled.div`
  align-items:center;
  text-align:center;
`;

export const IconOne = styled(FaStackOverflow)`
font-size:100px;
color:${primaryColor};
`;


export const IconTwo = styled(FaMagento)`
font-size:100px;
color:${primaryColor};
`;

export const IconTree = styled(SiGraphcool)`
font-size:100px;
color:${primaryColor};
margin:0;
`;

// export const Icon = styled(icon)`
//   margin-right: 0.5rem;
// `;
// export const Feature = styled.div`
//     justify-content:center;
//     align-items: center;
// `;
