// import React from 'react';
import one from '../../assets/images/Jisajili.svg';
import two from '../../assets/images/Weka_taarifa.svg';
import three from '../../assets/images/Kuwa_online.svg';
// import { IconOne, IconTree, IconTwo } from './Body.elements';
interface feat {
    icon? : any,
    title: String,
    desc: String
}

export const data: feat =  {
    icon: one,
    title: "Jisajili",
    desc: "Anza kwa kujisajili BURE! Mratibu wa shughuli au mtoa huduma."
};

export const data2: feat =  {
    icon: two,
    title: "Weka Taarifa Zako",
    desc: "Pakia orodha ya wadau wako, weka taarifa za shughuli yako."
};

export const data3: feat =  {
    icon: three,
    title: "Kuwa Online",
    desc: "Pata tovuti maalum kwaajili ya biashara/shughuli yako baada ya uhakiki. "
};

