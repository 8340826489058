import styled from "styled-components";

export const SelectInput = styled.select`
  width: 100%;
  height: 38px;
  /* background: #f8fafd; */
  color:#fff;
  /* color: gray; */
  /* height: 6%; */
  padding-left: 5px;
  font-size: 14px;
  background: #6b93ff;
  border: none;
  /* border: 1px solid #ddd; */
  /* margin-left: 10px; */
  border-radius: 0.25rem;
  display:flex;
  /* border:0.5px solid #f8fafd; */

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    margin: 5px 5px 5px 5px;
  }
`;