import React, { useEffect, useState } from 'react'
import { Loader } from '../../components/Loader/Loader';
import { ApiClient } from '../../Data/ApiClient';
import { Event } from '../../Data/Classes/Event';
import EventView from './Themes/default_theme';

const Controller:React.FC=()=> {
    const [eventObject, setEventObject]= useState<Event>();
    const [loading, setloading] = useState(true)
    let full_url = window.location.host;
    let url_parts = full_url.split('.');
    let event_id = url_parts[0];
    let formatedEventDate:any = new Date().toDateString;
    const getEventData = async (event_id: any) => {
        let event_object: Event;
        let config = {
            headers: {
                uid: event_id
            }
        };
       
        await ApiClient.get('events/byuid', config
        ).then(
            (response) => {
                setloading(false)
                let event_data = response.data;
                event_object = new Event(event_data.data);
                setEventObject(event_object);
            }
        ).catch(
            (error) => {
                return "";
            }
        );
      

    };

    
    useEffect(() => {
        getEventData(event_id);
          }
    , [event_id]);
      
    let kk:any =  eventObject?.event_date;

    formatedEventDate = new Date(kk !== undefined ? kk.replace(/-/g, "/") : new Date()).toDateString();
    var diff = +new Date(kk) - +new Date();
    var floo = Math.floor(diff/ (1000 * 60 * 60 * 24));
 
    var showModal:boolean;
    if(floo > 1){
        //show rsvp modal
        showModal = true;
  
    }else{
        //hide rsvp modal
        showModal = false;
    
    }
    var domObject;
    if(loading){
        domObject = <Loader size="large" page={true}/>;
    }else{
        domObject = <EventView eventObject={eventObject}  event_date={formatedEventDate} showModal={showModal}/>;
        // console.log("Complete event data ::: ",eventObject)
    }

    

    return(
        
           domObject 
        // {eventObject?.event_title}
           
        // </Section>
        
       
    );


}

export default Controller
