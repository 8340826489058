import React from 'react'
import {VictoryPie} from 'victory'
import {  ProgressHolderSM } from './ProgressChart.elements'


type progressOptions = {
    y:number;
}

const ProgressChartSM:React.FC<progressOptions> = ({y}) =>{
    return (
        <ProgressHolderSM>
        {/* <svg  width={200} height={200}>
        <text x={100} y={110} textAnchor="middle" >
            {y}%
        </text> */}
        <svg >
        {/* <text x={150} y={150} textAnchor="middle" >
            {y}%
        </text>  */}
        
        <VictoryPie 
        padAngle={0}
        labelComponent={<span/>}
        animate={{duration:1000}}
        standalone={false}
        innerRadius={20}
        width={100}
        height={100}
        // width={80} height={80}
        colorScale={["#06f", "#EEEEEE" ]}
        data={[{'key': "uuu", 'y': (100-y)}, {'key': "", 'y': y} ]}>
        </VictoryPie>
  
       
        </svg>
       </ProgressHolderSM>     
       
    )
}

export default ProgressChartSM
