import React, { useRef } from 'react'
import { useState } from 'react'
import Popup from 'reactjs-popup'
import { ApiClient } from '../../Data/ApiClient'
import { Guest } from '../../Data/Classes/Guest'
import { AppFormInput, AppGlobalButton, AppGlobalDeleteButton, FormErrorText, InputPlaceholder, InputUnit } from '../../pages/UserLogin/Login.elements'
import { NumberFormatter } from '../Common/Config'
import GlobalProgressLoader from '../Common/GlobalLoader'
import { ContactGroupChip } from '../DashboardPages/ContactGroups/ContactGroupChip'
import { ButtonsContainer, EditLoaderOverlay, EditModalContainer, EditTopSection, GuestEditHeader, GuestEditP, GuestMiddleSectionHeader, MiddleSection, PledgeDescriptionWrapper } from '../Modal/Modal.elements'
import Toast from '../Toast/Toast'
import { ContactCard, EditBg, MaintTableOpening, Td, Th, Tr, WholeTableContainer } from './TableListing.elements'
import { GlobalModalClose, GlobalModalWrapper } from '../DashboardPages/Main/Home.elements'
import { useHistory } from 'react-router-dom'
import FilterComponent from './FilterComponent'


type tableOptions = {
    th:any[];
    tr:Guest[]|undefined;
    eventid:any;
}


const TableListing:React.FC<tableOptions> = ({th,tr,eventid}) =>{
    var history = useHistory();
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [selectedGuestItem, setselectedGuestItem] = useState(0);
    const [pledgeAmount, setpledgeAmount] = useState("");
    const [paidAmount, setpaidAmount] = useState("");
    const [inputError, setinputError] = useState("");
    const [eventID, seteventID] = useState(0);
    const [showToast, setshowToast] = useState(false);
    const [toastDescription, settoastDescription] = useState("");
    const [operationStatus, setoperationStatus] = useState(false);
    const [isloading, setisloading] = useState(false)
    const [paidPledgeAmount, setpaidPledgeAmount] = useState(0);
    const [paidPledgeDescription, setpaidPledgeDescription] = useState();
    const [contactEditLoading, setcontactEditLoading] = useState(false)
    const [guestDetailsModalopen, setGuestDetailsModalOpen] = useState(false);
    const [selectedGuest, setSelectedGuest] = useState<Guest>(); 
    const [filterText, setFilterText] = useState("");

    const filteredItems = tr?.filter(item => item.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) !== -1)


    const handleRemoveContactFromGroup= (contactId:number,groupId:number) =>{
        setisloading(true);
        ApiClient.delete('groups/members',{data : {
            group_number: groupId,
            members: [contactId]
        },headers: {
            'uid': eventid
        }}).then((response)=>{
            setisloading(false);
                setoperationStatus(true);
                setshowToast(true);
                settoastDescription("Item Updated")
                history.push('/dashboard')
        }).catch((error)=>alert(error.message)).finally(()=>setisloading(false));
    }
    

    const handleClear = () => {
        if (filterText) {
          setFilterText("");
        }
      };

    const handleEditFormSubmit = (event:any) => {
        event.preventDefault();
        if(name === "" || name === undefined || name === null){
            setinputError("Name is required");
        }else if(phone === "" || phone === undefined || phone === null ){
            setinputError("Phone is required");
        }else{
            setinputError("")
            // EditGuestItem()
           
        }
    }

    const handleCloseClick = ()=>{
        setshowToast(!showToast);
    }

    let config = {
        headers: {
            uid: eventid
        }
    };

    const UpdatePledgeOnGuestItem = (pledge_id:number)=>{
        setisloading(true);
        ApiClient.post('funds',{
            pledge_id:pledge_id,
            description:paidPledgeDescription,
            amount:paidPledgeAmount
        },config).then(
            (response)=>{
                setisloading(false);
                setoperationStatus(true);
                setshowToast(true);
                settoastDescription("Item Updated")
                history.push('/dashboard')
            }
        ).catch(
            (error)=>{
                setisloading(false);
                setshowToast(true);
            setoperationStatus(false);
            settoastDescription("An error occured")
            }
        )
    }

    const AddPledgeToGuestItem = ()=>{
        setisloading(true)
        ApiClient.post('pledges',{
            id: selectedGuestItem,
            event_id: eventID,
            full_name: name,
            phone_number: phone,
            mobile: phone,
            email: email,
            amount: pledgeAmount,
            attending:"1",
            amount_collected: paidAmount,
            pledges:null
        },config).then((response)=>{
            setshowToast(true);
            setisloading(false)
            if(response.status === 201){
                setoperationStatus(true);
                // settoastDescription("")
                settoastDescription("Item Updated")
                history.push('/dashboard')
            }
        }).catch((error)=>{
            setisloading(false)
            setshowToast(true);
            setoperationStatus(false);
            settoastDescription("An error occured")
            console.log("Guest edit error response >> ",error);
        })
    }

    const EditGuestItem = ():void=>{
        setcontactEditLoading(true)
        setshowToast(false)
        ApiClient.put('guest_list/'+selectedGuestItem,
        {
            id: selectedGuestItem,
            event_id: eventID,
            name: name,
            phone_number: phone,
            email: email
        },config).then((response)=>{
            setshowToast(true);
            setoperationStatus(true)
            setisloading(false)
            if(response.status === 200){
                settoastDescription("Item Updated")
                history.push('/dashboard')
            }
            
        }).catch((error)=>{
            setisloading(false)
            setshowToast(true);
            setoperationStatus(false);
            settoastDescription("An error occured")
            console.log("Guest edit error response >> ",error);
        })
    }

    const DeleteGuestItem = ():boolean=>{
        var res = false;
        setcontactEditLoading(true)
        setshowToast(false)
        ApiClient.delete('guest_list/'+selectedGuestItem,
        config).then((response)=>{
            setshowToast(true);
            setoperationStatus(true)
            setisloading(false)
            // if(response.status === 200){
                settoastDescription("Item Deleted")
                history.push('/dashboard')
            // }
            res = true;
            
            
        }).catch((error)=>{
            res = false;
            setisloading(false)
            setshowToast(true);
            setoperationStatus(false);
            settoastDescription("An error occured")
        
        })

        return(res)
    }
    return (
        
        <WholeTableContainer>
                {isloading && <EditLoaderOverlay><GlobalProgressLoader isVisible={true} width={30} height={30}/></EditLoaderOverlay>}
                <FilterComponent onClear={handleClear} onFilter={(e: any) => setFilterText(e.target.value)} filterText={filterText}/>
            <MaintTableOpening>

                <thead>
                    
                <Tr>
                        {
                        
                        th.map((title,index)=>
                            <Th key={index} can_hide={index === 1 || index === 2 ? true : false}>{title}</Th>
                            
                        )}
                </Tr>
                </thead>
                
           
                <tbody>
                {filteredItems?.map((guest,index)=>{ 
                
                   return(
                    <Tr key={index} >
                         <Td><ContactCard/>{guest.name}</Td>
                         <Td  can_hide={true}>{guest.groups?.map((group,index)=><ContactGroupChip key={index} iconClickAction={(id)=>handleRemoveContactFromGroup(guest.id,id)} id={group.id} title={group.group_name}/>)}</Td>
                    <Td can_hide={true}>{guest.phone_number}</Td>
                    <Td >{`${guest.pledges?.amount !== undefined ? NumberFormatter.format(+guest.pledges.amount) : 'NA'} / ${guest.pledges?.amount_collected !== undefined ? NumberFormatter.format(+guest.pledges.amount_collected) : 'NA'}`}</Td>
                    <Td></Td>
                    <Td onClick={()=>{setGuestDetailsModalOpen(!guestDetailsModalopen);
                     setname(guest.name);
                     setemail(guest.email);
                     setphone(guest.phone_number);
                     setselectedGuestItem(guest.id);
                     seteventID(guest.event_id);
                     setSelectedGuest(guest);
                    }}><EditBg>+</EditBg></Td>
              
                   
                    </Tr>
                   )
})}
             
                </tbody>
                
            </MaintTableOpening>
            {showToast && <Toast closeClick={handleCloseClick} description={toastDescription} type={operationStatus ? 'Sucess' : 'Error'}/>}
            <Popup open={guestDetailsModalopen} closeOnEscape nested closeOnDocumentClick  modal >
                       <GlobalModalWrapper>
                        <EditModalContainer>
                                  {isloading && <EditLoaderOverlay><GlobalProgressLoader isVisible={true} width={30} height={30}/></EditLoaderOverlay>}
                                  
                                <EditTopSection>
                                <GuestEditHeader>
                                   
                                    <GlobalModalClose onClick={()=>{setGuestDetailsModalOpen(!guestDetailsModalopen)}}>&times;</GlobalModalClose>
                                    <p>
                                       {name}<br/> 
                                       {phone}
                                    </p>

                                    <Popup modal nested trigger={<GuestEditP>Edit</GuestEditP>}>
                                        <>
                                        <EditModalContainer>
                                        {contactEditLoading && <EditLoaderOverlay><GlobalProgressLoader isVisible={true} width={30} height={30}/></EditLoaderOverlay>}
                                        <EditTopSection>

                                        <GuestEditHeader>
                                            <p>Edit Contact</p>
                                        </GuestEditHeader>

                                        </EditTopSection>

                                        <MiddleSection>
                                            <form onClick={(e:any)=>{e.preventDefault();}}>

                                            <InputUnit>
                                <InputPlaceholder>Contact Name:</InputPlaceholder>
                                <AppFormInput required  defaultValue={name} onChange={(e:any)=>{setname(e.currentTarget.value)}}  type="text" />
                                </InputUnit>
                                <InputUnit>
                                <InputPlaceholder>Contact Phone:</InputPlaceholder>
                                <AppFormInput required defaultValue={phone}  type="number" onChange={(e:any)=>{setphone(e.currentTarget.value)}}/>
                                </InputUnit>
                                <InputUnit>
                                <InputPlaceholder>Contact Email:</InputPlaceholder>
                                <AppFormInput  defaultValue={email}  type="email" onChange={(e:any)=>{setemail(e.currentTarget.value)}} />
                                </InputUnit>

                                <ButtonsContainer>
                                <AppGlobalButton disabled={contactEditLoading} onClick={(event:any)=>{
                                    event.preventDefault();
                                    EditGuestItem()

                                }}>Save</AppGlobalButton>
                                <AppGlobalDeleteButton disabled={contactEditLoading} onClick={(event:any)=>{
                                    event.preventDefault();
                                    DeleteGuestItem()

                                }}>Delete</AppGlobalDeleteButton>
                                </ButtonsContainer>

                                            </form>
                                        </MiddleSection>
                                        </EditModalContainer>
                                        </>
                                    </Popup>
                                </GuestEditHeader>
                                </EditTopSection>
                                <MiddleSection>
                                    <form onSubmit={handleEditFormSubmit} >
                              
                                <GuestMiddleSectionHeader>
                                    Pledge
                                </GuestMiddleSectionHeader>

                                {
                                    selectedGuest?.pledges?.amount !== undefined ? <>
                                          <InputUnit>
                                <InputPlaceholder>Pledge Amount:</InputPlaceholder>
                                <AppFormInput disabled defaultValue={NumberFormatter.format( selectedGuest.pledges?.amount !== undefined ? +selectedGuest.pledges?.amount : 0 )}  type="text" />
                                </InputUnit>
                                <InputUnit>
                                <InputPlaceholder>
                                Paid Amount:
                                </InputPlaceholder>
                                <AppFormInput disabled defaultValue={NumberFormatter.format( selectedGuest.pledges?.amount !== undefined ? +selectedGuest.pledges?.amount_collected : 0 )}  type="text" />
                                </InputUnit>
                                    </>: undefined
                                }
                               {
                                   selectedGuest?.pledges?.amount !== undefined ?  <InputUnit>
                                   <AppFormInput required onChange={(val:any)=>setpaidPledgeAmount(val.currentTarget.value)} type="number" placeholder="Amount" />
                               </InputUnit> : undefined
                               }

                               {
                                   selectedGuest?.pledges?.amount !== undefined ?  <InputUnit>
                                   <PledgeDescriptionWrapper onChange={(val:any)=>setpaidPledgeDescription(val.currentTarget.value)} placeholder="Description: if any "/>
                               </InputUnit> : undefined
                               }
                               
                                {
                                    selectedGuest?.pledges?.amount === undefined ? <InputUnit> <AppFormInput value={pledgeAmount} onChange={(val:any)=>{setpledgeAmount(val.currentTarget.value)}} type="number" placeholder="Enter Pledge Amount"/> </InputUnit> : undefined
                                   
                                }
                                {
                                    selectedGuest?.pledges?.amount_collected === undefined ? 
                                    <InputUnit>
                                    <AppFormInput value={paidAmount} onChange={(val:any)=>{setpaidAmount(val.currentTarget.value)}} type="number" placeholder="Enter Paid Amount"/>
                                    </InputUnit> : undefined
                                }
                                <ButtonsContainer>
                                    <AppGlobalButton disabled={isloading}  formAction="submit" onClick={
                                        ()=>{
                                          if(pledgeAmount !== ""){
                                                AddPledgeToGuestItem()
                                          }else{
                                            if(paidPledgeAmount !== 0){
                                               if(selectedGuest?.pledges?.id !== undefined ){ UpdatePledgeOnGuestItem(selectedGuest?.pledges?.id)}
                                            }else{
                                                EditGuestItem()
                                            }
                                          }
                                           
                                        }
                                    }>Save</AppGlobalButton>
                                  
                                </ButtonsContainer>

                                <FormErrorText>{inputError}</FormErrorText>
                                    </form>
                                </MiddleSection>
                                </EditModalContainer>
                                </GlobalModalWrapper>
                               
                        
                    </Popup>
        </WholeTableContainer>
    )
}

export default TableListing
