import styled,{ createGlobalStyle } from 'styled-components';




const GlobalStyle = createGlobalStyle`

 body {
    margin: 0px 0px 0px 0px;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Manrope', sans-serif;
  }
`;




export const Container = styled.div`
  z-index: 1;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 0px 0px 0px;
  @media screen and (max-width: 991px) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;

type btn = {
  primary? : boolean,
  big? : boolean,
  fontBig?: boolean
}

export const CTABTN = styled.button`
  margin:10px;
  border-radius: 6px;
  background-color: rgb(233, 238, 251);
  border-radius: 6px;
    border: none;
    font-weight: 400;
    width: 130px;
    padding: 14px;
    color: rgb(0, 102, 255);

    cursor: pointer;

    :hover{
      background: #06e;
      color: #fff;
    }

    @media only screen and (max-width: 768px) {
      width: 100px;
      padding: 7px;
      margin: 5px;
    }
`;

export const Button = styled.button<btn>`
 border-radius: 6px;
  background: ${({ primary }) => (primary ? '#06e' : '#0467FB')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? '#0467FB' : '#4B59F7')};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export default GlobalStyle;